import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../../i18n/localized-link"
import { ProductModel } from "../../../model/productModel"
import CartButton from "../actions/cart-button/cart-button"
import "./product-cart-preview.sass"

const ProductCartPreview = ({ product }) => {
  return (
    <div className={"_fp-product-cart-preview"}>
      <CartButton
        large={false}
        product={product}
        buttonStyle={"remove"}
      ></CartButton>
      <LocalizedLink to={product.getUrl()}>
        <h4 className={"_fp-heading-5 _fp-product-cart-preview__product-name"}>
          {product.name}
        </h4>
      </LocalizedLink>
      {product.sample_size && (
        <h5 className={"_fp-heading-6 _fp-product-cart-preview__sample-size"}>
          {product.sample_size}
        </h5>
      )}
    </div>
  )
}

ProductCartPreview.propTypes = {
  product: PropTypes.instanceOf(ProductModel).isRequired,
}

export default ProductCartPreview
