import { WebformComponent } from "@brynlabs/webforms-react"
import React, { useState } from "react"
import { connect } from "react-redux"

import Button from "../components/button/button"
import FormErrorBanner from "../components/form-error-banner/form-error-banner"
import { WebformsAdditionalData } from "../components/form/additional-data/additional-data"
import { WebformsCheckbox } from "../components/form/checkbox/checkbox"
import { WebformsCountrySelect } from "../components/form/country-select/country-select"
import FormField from "../components/form/form-field/form-field"
import { WebformsInput } from "../components/form/input/input"
import { WebformsMarketSelect } from "../components/form/market-select/market-select"
import { WebformsTextarea } from "../components/form/textarea/textarea"
import InfoSection from "../components/info-section/info-section"
import PageHeader from "../components/page-header/page-header"
import ProductCartPreview from "../components/product/product-cart-preview/product-cart-preview"
import RichText from "../components/rich-text/rich-text"
import SEO from "../components/seo"
import Tile from "../components/tile/tile"
import TileContentSpacer from "../components/tile/tile-content-spacer/tile-content-spacer"
import useProducts from "../hooks/useProducts"
import { useTranslation } from "../i18n/translate"
import { ProductModel } from "../model/productModel"
import useIsClient from "../util/is-client"
import trackCustomEvent from "../util/trackCustomEvent"

const RequestProductSamplePage = ({
  productsInCart,
  removeAllFromCart,
  location,
}) => {
  const { t } = useTranslation()

  const isClient = useIsClient()

  const [loading, setLoading] = useState(false)
  const [formSent, setFormSent] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [country, setCountry] = useState()

  const allProducts = productsInCart.map((product) => new ProductModel(product))

  const productsForWebforms = ProductModel.prepareForWebforms(
    allProducts,
    country,
  )
  let recipientEmail = "info@munzing.com"

  const firstKey = Object.keys(productsForWebforms)[0]
  if (firstKey && firstKey !== "other") {
    recipientEmail = firstKey
  }

  const isEmpty = !(isClient && allProducts.length > 0 && !formSent)

  const reset = () => {
    removeAllFromCart()
  }

  return (
    <>
      <SEO
        pathName={location.pathname}
        indexPage={false}
        titleTranslatedString={t("PRODUCTS-SAMPLE_REQUEST-META_TITLE")}
        descriptionTranslatedString={t(
          "PRODUCTS-SAMPLE_REQUEST-META_DESCRIPTION",
        )}
      />
      <PageHeader
        levelTwoTitle={t("PRODUCTS-SAMPLE_REQUEST-META_TITLE")}
        levelThreeTitle={t("PRODUCTS-SAMPLE_REQUEST-META_TITLE")}
      />

      <div className={"_fp-global-container _fp-grid--margin-top"}>
        <div className="_fp-grid _fp-grid--gap _fp-order-product-sample">
          {!isEmpty && (
            <>
              <div className="_fp-col-4 _fp-col-5sd _fp-col-12st _fp-col-12sp _fp-col-12p _fp-col-12t">
                <Tile
                  autoHeight={true}
                  titleTranslatedString={t(
                    "PRODUCTS-SAMPLE_REQUEST-PRODUCT_SAMPLES",
                  )}
                >
                  {allProducts.map((product, index, array) => {
                    return (
                      <span key={"request_product_sample_" + index}>
                        <ProductCartPreview
                          autoHeight={true}
                          product={product}
                        />
                        {index !== array.length - 1 && (
                          <TileContentSpacer size={"extra-small"} />
                        )}
                      </span>
                    )
                  })}
                </Tile>
              </div>
              <div className="_fp-col-8 _fp-col-7sd _fp-col-12st _fp-col-12sp _fp-col-12p _fp-col-12t">
                <Tile
                  titleTranslatedString={t(
                    "PRODUCTS-SAMPLE_REQUEST-ORDER_DETAILS",
                  )}
                >
                  <WebformComponent
                    onSuccess={() => {
                      setFormSent(true)
                      setHasError(false)
                      reset()
                      setLoading(false)
                      trackCustomEvent({
                        category: "order_product_sample",
                        action: "form_sent",
                      })
                    }}
                    onSubmit={() => {
                      setLoading(true)
                      setHasError(false)
                    }}
                    onError={(err) => {
                      console.error(err)
                      setHasError(true)
                      setLoading(false)
                    }}
                    formToken={
                      "LcqmAdWtBZAKnkJaUlSgaPSuvLovwNmvvKxfxdrTfRTYyjZKeQjlfrjbNdZVxlJw"
                    }
                    webformsEndpoint={
                      process.env.GATSBY_TEST_WEBFORMS === "true"
                        ? "https://staging.webforms.brynlabs.com/api"
                        : undefined
                    }
                  >
                    <WebformsAdditionalData
                      additionalData={productsForWebforms}
                      webformsKey={"products"}
                    />
                    <div className="_fp-grid _fp-grid--gap">
                      <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={
                            t("GLOBAL-CONTACT_FORM-FULL_NAME") + " *"
                          }
                        >
                          <WebformsInput
                            autocomplete={"name"}
                            required={true}
                            webformsKey={"full_name"}
                            darkAppearance
                            name={"full_name"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={
                            t("GLOBAL-CONTACT_FORM-COMPANY_NAME") + " *"
                          }
                        >
                          <WebformsInput
                            autocomplete={"organization"}
                            required={true}
                            darkAppearance
                            webformsKey={"company_name"}
                            name={"company_name"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={
                            t("GLOBAL-CONTACT_FORM-ADDRESS_LINE_1") + " *"
                          }
                        >
                          <WebformsInput
                            autocomplete={"address-line1"}
                            required={true}
                            darkAppearance
                            webformsKey={"address_line_1"}
                            name={"address_line_1"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={t(
                            "GLOBAL-CONTACT_FORM-ADDRESS_LINE_2",
                          )}
                        >
                          <WebformsInput
                            autocomplete={"address-line2"}
                            darkAppearance
                            webformsKey={"address_line_2"}
                            name={"address_line_2"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-4 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={
                            t("GLOBAL-CONTACT_FORM-POSTAL_CODE") + " *"
                          }
                        >
                          <WebformsInput
                            autocomplete={"postal-code"}
                            required={true}
                            darkAppearance
                            webformsKey={"postal_code"}
                            name={"postal_code"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-8 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={
                            t("GLOBAL-CONTACT_FORM-CITY") + " *"
                          }
                        >
                          <WebformsInput
                            autocomplete={"address-level2"}
                            required={true}
                            darkAppearance
                            webformsKey={"city"}
                            name={"city"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-3 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={t(
                            "GLOBAL-CONTACT_FORM-STATE_PROVINCE",
                          )}
                        >
                          <WebformsInput
                            autocomplete={"address-level1"}
                            darkAppearance
                            webformsKey={"state_province"}
                            name={"state_province"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-9 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={
                            t("GLOBAL-CONTACT_FORM-COUNTRY") + " *"
                          }
                        >
                          <WebformsCountrySelect
                            onSelect={setCountry}
                            required={true}
                            darkAppearance
                            name={"country"}
                            webformsKey={"country"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={
                            t("GLOBAL-CONTACT_FORM-PHONE") + " *"
                          }
                        >
                          <WebformsInput
                            autocomplete={"tel"}
                            required={true}
                            darkAppearance
                            webformsKey={"phone"}
                            name={"phone"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={
                            t("GLOBAL-CONTACT_FORM-E_MAIL") + " *"
                          }
                        >
                          <WebformsInput
                            type={"email"}
                            autoComplete={"email"}
                            required={true}
                            darkAppearance
                            webformsKey={"email"}
                            name={"email"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={t(
                            "GLOBAL-CONTACT_FORM-TARGET_APPLICATION",
                          )}
                        >
                          <WebformsMarketSelect
                            darkAppearance
                            webformsKey={"application"}
                            name={"application"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={t(
                            "GLOBAL-CONTACT_FORM-PROJECTED_MONTHLY_VOLUME",
                          )}
                        >
                          <WebformsInput
                            darkAppearance
                            webformsKey={"projected_volume"}
                            name={"projected_volume"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-12">
                        <FormField
                          fullWidth={true}
                          titleTranslatedString={t(
                            "GLOBAL-CONTACT_FORM-COMMENT",
                          )}
                        >
                          <WebformsTextarea
                            darkAppearance
                            webformsKey={"comment"}
                            name={"comment"}
                          />
                        </FormField>
                      </div>
                      <div className="_fp-col-12">
                        <WebformsCheckbox
                          required={true}
                          webformsKey={"privacy"}
                          name={"privacy"}
                          large={true}
                          translatedLabel={
                            <RichText
                              textTranslationKey={
                                "GLOBAL-CONTACT_FORM-PRIVACY_POLICY_MESSAGE"
                              }
                            ></RichText>
                          }
                          darkAppearance={true}
                        />
                      </div>
                      <div className="_fp-col-12">
                        {!loading && (
                          <Button
                            buttonSize={"large"}
                            type={"submit"}
                            buttonCallback={() => {
                              document.body.scrollTop = 0 // For Safari
                              document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
                            }}
                            buttonLabel={t(
                              "PRODUCTS-SAMPLE_REQUEST-REQUEST_SAMPLE_CTA",
                            )}
                          />
                        )}
                        {loading && (
                          <div>{t("GLOBAL-CONTACT_FORM-SENDING_MESSAGE")}</div>
                        )}
                      </div>
                      {hasError && !loading && (
                        <FormErrorBanner
                          textTranslatedString={t(
                            "GLOBAL-CONTACT_FORM-ERROR_MESSAGE",
                          )}
                          emailAddress={recipientEmail}
                        ></FormErrorBanner>
                      )}
                    </div>
                  </WebformComponent>
                </Tile>
              </div>
            </>
          )}
          {!formSent && isEmpty && (
            <div className="_fp-col-12">
              <Tile
                titleTranslatedString={t(
                  "PRODUCTS-SAMPLE_REQUEST-PRODUCT_SAMPLES",
                )}
              >
                <InfoSection
                  textTranslatedString={t(
                    "PRODUCTS-SAMPLE_REQUEST-EMPTY_PRODUCT_CART_INFO",
                  )}
                />
              </Tile>
            </div>
          )}

          {formSent && (
            <div className="_fp-col-12">
              <Tile
                titleTranslatedString={t(
                  "GLOBAL-CONTACT_FORM-MESSAGE_SUBMITTED_SUCCESSFULLY",
                )}
              >
                <p>{t("GLOBAL-CONTACT_FORM-CONFIRMATION_MAIL_EXPLAINATION")}</p>
                <TileContentSpacer size={"small"}></TileContentSpacer>
                <Button
                  buttonCallback={() => {
                    setFormSent(false)
                  }}
                  buttonLabel={t("GLOBAL-OK")}
                ></Button>
              </Tile>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ cart }) => {
  return {
    productsInCart: cart,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeAllFromCart: () => dispatch({ type: "REMOVE_ALL_FROM_CART" }),
  }
}

const withProducts =
  (WrappedComponent) =>
  ({ productsInCart, ...props }) => {
    const products = useProducts()

    props.productsInCart = products.filter(
      (product) => productsInCart.findIndex((c) => c.id === product.id) !== -1,
    )
    return <WrappedComponent {...props} />
  }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withProducts(RequestProductSamplePage))
